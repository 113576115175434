import Swiper from 'swiper'
import {Width, Delay} from '../util'

new Swiper('.newswrap__scroller', {
  slidesPerView: 3,
  slidesPerColumn: 2,
  spaceBetween: 0,
  navigation: {
    nextEl: '.newswrap__page--next',
    prevEl: '.newswrap__page--prev',
  },
  pagination: {
    el: '.newswrap__nav',
    clickable: true,
    bulletClass: 'newswrap__page',
    bulletActiveClass: 'newswrap__page--selected',
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  },
});

new Swiper('.footer__nav', {
  slidesPerView: 'auto',
  grabCursor: true,
});

if (window.screen.width <= Width.tablet.m - 1) {
  new Swiper('.chapters', {
    slidesPerView: 'auto',
    grabCursor: true,
  });
}