        function init() {
            var eventItemMap = new ymaps.Map("map", {
                center: [47.237808054756,39.712367714831],
                zoom: 16,
                controls: []
            });

            eventItemMap.geoObjects
                    .add(new ymaps.Placemark([47.237808054756,39.712367714831], {
                        balloonContent: '<strong>ДГТУ</strong>'
                    }, {
                        preset: 'islands#dotIcon',
                        iconColor: '#3b5998'
                    }))
            eventItemMap.behaviors.disable('scrollZoom');
        }
        ymaps.ready(init);