import {Width, Delay} from './util'
import './app/sliders'
import './app/map'

$(document).ready(() => {
  let chapters__item = document.getElementsByClassName('chapters__item');

  if (window.screen.width >= Width.desktop.m) {
    for (let i = 0; i < 5; i++) {
        chapters__item[i].classList.add('chapters__item--dark')
    }
    for (let i = 5; i < 10; i++) {
        chapters__item[i].classList.add('chapters__item--semidark')
    }
    for (let i = 10; i < chapters__item.length; i++) {
        chapters__item[i].classList.add('chapters__item--light')
    }
  }

  if (window.screen.width >= Width.tablet.m && window.screen.width <= Width.desktop.m - 1) {
    for (let i = 0; i < 4; i++) {
        chapters__item[i].classList.add('chapters__item--dark')
    }
    for (let i = 4; i < 8; i++) {
        chapters__item[i].classList.add('chapters__item--semidark')
    }
    for (let i = 8; i < chapters__item.length; i++) {
        chapters__item[i].classList.add('chapters__item--light')
    }
  }

  const newsbox__picture = document.querySelectorAll('.newsbox__picture');
  newsbox__picture.forEach((item) => {
    item.parentNode.setAttribute('style','background-image:url(' + item.getAttribute("src") + ');');
  });
});