const Width = {
  tablet: {
    s: 480,
    m: 768,
    l: 992
  },
  desktop: {
    s: 1200,
    m: 1366,
    l: 1480
  }
};

export {
  Width
}